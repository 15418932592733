#header_row {
  height:15vh;
  height:15dvh;
  min-height:60px;
  background-color: #cab2d6;
  position: relative;
}

#logo {
  float:right;
  margin-left:auto;
  margin-right:3vh;
  height:15vh;
  height:15dvh;
  min-width: 60px;
  min-height: 60px;
}

#logout {
  max-height: 34px;
  min-height: 28px;
  height: 4vh;
  font-size: max(1.7vh, 1rem);
  opacity: 0.7;
  margin-left: 10px;
}

.logowrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.userwrapper {
  position: absolute;
  top: max(7.5vh - 40px, 0px);
  left: 20px;
  width: 50%;
  height: 100%;
  font-size: max(3.4vh, 1.4rem)
}
