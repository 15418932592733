
@keyframes expandpopup {
  0% {
    position: absolute;
    top: 50dvh;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1, 0.1);
  }
  100% {
    position: absolute;
    top: 50dvh;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

.popup {
  position: absolute;
  top: 50dvh;
  left: 50%;
  background-color: #bbbe;
  animation-name: expandpopup;
  animation-duration: 0.5s;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 5;
}

.popupcontent {
  display: flex;
  padding: 5vh;
  white-space: normal;
}

.optionrow {
  position:absolute;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  background-color: #bbbe;
  white-space: nowrap;
  width: 100%;
}

.optionbutton {
  font-size: 1.5rem;
  margin: max(0.5vw, 0.5rem);
  white-space: nowrap;
}

.optionbutton:hover {
  opacity: 0.5;
}

.first {
  margin-left: auto;
}

.entry {
  font-size:1.2rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.text_input {
  font-size: 1.2rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
