.tabs {
  display: flex;
  align-items: stretch;
}

.tabwindow {
  margin-left: auto;
  margin-right: auto;
}

.tab {
  flex: 1;
  text-align: center;
  font-size: 2rem;
  color: #777;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 4px solid black;
  background-color: lightgray;
}

.tab:hover {
  background-color: darkgray;
  color: #000;
}

.tabselect {
  border-bottom: 0;
  background-color: darkgray;
  color: #000;
  font-weight: bold;
}

.tab:last-child {
  border-right: 2px solid black;
}

